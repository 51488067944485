@use 'src/styles/vars';

.user__form {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  background-color: vars.$color-secondary;

  table {
    width: 100%;
  }

  thead tr {
    background-color: vars.$color-primary;
    color: vars.$color-sand;
    text-align: center;
  }

  th, td {
    padding: 12px 15px;
    text-align: center;
  }

  tbody tr {
    border-bottom: 1px solid vars.$color-sand;
  }

  tbody tr:nth-of-type(even) {
    background-color: vars.$color-sand;
  }

  tbody tr:last-of-type {
    border-bottom: 2px solid vars.$color-primary;
  }

  tbody tr.active-row {
    font-weight: bold;
    color: vars.$color-primary;
  }
}
