@use 'src/styles/vars';

.recipes__footer{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: vars.$color-secondary;
  color: vars.$color-primary;
  padding: 1.2rem;
  font-size: 1.2rem;
  position: absolute;
  width: 100%;
  //margin-top: 2rem;
  bottom: -100px;
  // border-top-left-radius: 1.5rem;
  // border-top-right-radius: 1.5rem;
  z-index: 90;
}

.recipes__footer__home{
  font-family: vars.$font-title;
  font-size: 1.5rem;
}

.desktop {
  display: none;
}

@media only screen and (min-width: 1024px) {

  .desktop {
    display: block;
  }
}
