@use 'src/styles/vars';

.background__image {
  background-image: url('../../assets/images/background.jpg');
  background-size: cover;
  background-position: center;
  margin-bottom: -50px;
  //margin-bottom: -1.2rem;
}

@supports not (backdrop-filter: none) {
  .background__image {
    background-image: url('../../assets/images/background-blur.jpg');
    background-size: cover;
    background-position: center;
  }
}

.generator {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 15rem;
  //margin-bottom: -50px;
  background-color: rgba( 251, 246, 237, 0.75 );
  backdrop-filter: blur( 6px );
  -webkit-backdrop-filter: blur( 6px );


  &__flexblock {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__description {
    text-align: center;
  }


  &__title {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  &__text {
    max-width: 450px;
    margin: 0.5rem;
  }

  &__section {
    padding: 2rem;
    //max-width: 350px;
    height: 350px;
    margin: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: vars.$color-secondary;
    border-radius: 4rem;
    box-shadow: 0 8px 32px 0 rgba(117, 117, 117, 0.37);
    position: relative;

    &__logo {
      width: 200px;
      cursor: pointer;
      animation: scale 2s infinite;
    }

    @keyframes scale {
      from {
        transform: scale(1);
      }
      50% {
        transform: scale(1.05);
      }
      to {
        transform: scale(1);
      }
    }

    &__logo:hover {
      transform: scale(1.1);
      animation: transform 1s;
    }

    &__arrow {
      width: 15%;
      cursor: pointer;
    }

    &__click {
      font-weight: 700;
    }
  }

  &--animation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: opacity 0.6s;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: 0.5s;
    animation: opacity 0.6s;
    background-color: #00382a1f;
    border-radius: 5rem;
    padding: 0.5rem;

    &__section {
      display: flex;

      &__favorites {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    &__section input {
      display: flex;
      width: 85px;
    }

    &__section label {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &__form label {
    margin: 0.3rem 0.5rem;
  }

  &__form button {
    margin-top: 0.6rem;
  }

  &__form select {
    width: 145px;
  }

}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (min-width: 768px) {

  .generator {

    &__section {
      width: 400px;
      height: 350px;
    }
  }

}

@media only screen and (min-width: 1024px) {

  .generator {
    //margin-bottom: 3rem;

  &__flexblock {
    flex-direction: row-reverse;
    justify-content: center;
  }

  &__description {
    text-align: left;
    padding: 0.5rem;
  }

  &__title {
    text-align: left;
    margin-left: 0.5rem;
  }

}



}
