@use 'src/styles/vars';

.modal {
  margin: 1.5rem 0rem;

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 0.5rem 0rem;
  }

  &__button {
    margin: 0rem 0.5rem;
  }
}
