@use 'src/styles/vars';

.recipe {
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-height: 90vh;

  &__block {
    max-width: 31.25rem;
    width: 80%;
  }

  &__block--left {
    margin-right: 0.8rem;
  }

  &__block--right {
    margin-left: 0.8rem;
  }
}

.recipe__desktop {
  margin-top: 3.1rem;
  display: none;
}

.recipe__mobile {
  width: 100%;

  &__content {
    margin: auto;
    width: 90%;
  }
}

@media only screen and (min-width: 1024px) {
  .recipe__desktop {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .recipe__mobile {
    display: none;
  }
}

// @media only screen and (min-width: 1118px) {
//   .recipe {
//     &__block {

//       margin: 30px;
//       width: 50%;
//     }
//   }
// }
