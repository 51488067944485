@use 'src/styles/vars';

.connection {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0rem;
  //height: 100vh;

  &__message {
    margin-bottom: 1.5rem;
  }

  &__title {
    margin-bottom: 1.5rem;
  }

  &__content {
    min-width: 300px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: vars.$color-primary;
    background-color: vars.$color-secondary;
    border-radius: 1.5rem;
    padding: 1.5rem;

    &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 2rem;

      &__input {
        display: flex;
        flex-direction: column;
        margin: 0.6rem 0 0.3rem 0;
      }

      &__input:first-child {
        margin-top: 0rem;
      }

      &__label {
        font-size: 0.9rem;
        font-weight: 700;
        margin: 0px 1.1rem 0.3rem 1.1rem;
      }

      &__field {
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
      }

    }

    &__text {
      &--forgotten-password {
        font-size: 0.85rem;
        margin-bottom: 0.6rem;
      }
    }

    &__button {
      box-sizing: border-box;
      font-weight: 800;
      font-size: 0.85rem;
      text-transform: uppercase;
      padding: 0.75rem 1.25rem;
      margin: 0 1.25rem;
      color: vars.$color-sand;
      background-color: vars.$color-primary;
      border-radius: 1.875rem;
    }

    &__inscription {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__text {
        margin-bottom: 0.9rem;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .connection {
    &__content {
      width: 30%;
      max-width: 450px;
    }
  }
}
