@use 'src/styles/vars';

// MOBILE
@media only screen and (max-width: 768px) {

  .recipe__form{
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.4rem;
    background-color: vars.$color-secondary;
    border-radius: 2rem;
  }
  .label{
    font-weight: bold;
    margin: 1rem;
    padding: 0.5rem;
    font-size: 1.4rem;
  }

  .input{
    margin: 0.8rem;
    font-size: 1rem;
  }

  .choice__text{
    margin-left: 1.5rem;
    text-transform: capitalize;
    font-weight: bold;
  }

  .difficulty__select{
    width: 8rem;
  }

  .nutri__score{
    width: 4rem;
  }

  .recipe__form__div__1{
    display: flex;
    flex-direction: column;
  }
  .recipe__form__div__1 input{
    display: block;
    margin: 1rem 0;
  }

  .recipe__form__div__2{
    display: flex;
    flex-direction: column;
  }

  .recipe__form__div__3{
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  .recipe__form__div__4{
    display: flex;
    flex-direction: column;
  }

  .recipe__form__div__4 input{
    margin: 1rem;
  }
  .recipe__form__div__4 select{
    margin: 1rem;
  }

  .recipe__form__delete__icon{
    width: 1.6rem;
    margin-left: 1rem;
  }

  .submit__button{
    padding: 2rem;
    display: flex;
    justify-content: center;
  }

  .ingredient__element{
    padding: 0 1rem;
  }

  .ingredient__element{
    padding: 0 1rem;
    font-size: 1.2rem;
  }

  .step__element{
    padding: 0 1rem;
    font-size: 1.2rem;
    display: inline;
  }

  .recipe__form__image .input{
    font-size: 1rem;
    width: 100%;
  }

  .ingredient__button, .step__button{
    width: fit-content;
    margin: auto;
  }

  .tags__cloud{
    display: flex;
    flex-wrap: wrap;
  }

  .categories__cloud{
    display: flex;
    flex-wrap: wrap;
  }

  .seasons__cloud{
    display: flex;
    flex-wrap: wrap;
  }

  .submit__button{
    display: flex;
    flex-wrap: wrap;
  }
}

//DESKTOP
@media only screen and (min-width: 768px) {
  .recipe__form{
    padding: 2rem;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.4rem;
    background-color: vars.$color-secondary;
    border-radius: 2rem;
    margin-bottom: 3rem;
  }
  .label{
    font-weight: bold;
    margin: 1rem;
    padding: 0.5rem;
    font-size: 1.4rem;
  }

  .input{
    margin: 0.8rem;
    font-size: 1rem;
  }

  .choice__text{
    margin-left: 1.5rem;
    text-transform: capitalize;
    font-weight: bold;
  }

  .difficulty__select{
    width: 8rem;
  }

  .nutri__score{
    width: 4rem;
  }

  .recipe__form__div__1{
    display: flex;
    flex-wrap: wrap;
  }
  .recipe__form__div__1 input{
    display: block;
    margin: 1rem 0;
  }

  .recipe__form__div__3{
    margin-top: 1rem;
  }

  .recipe__form__div__4 input{
    margin: 1rem;
  }
  .recipe__form__div__4 select{
    margin: 1rem;
  }

  .recipe__form__delete__icon{
    width: 1.6rem;
    margin-left: 1rem;
  }

  .submit__button{
    padding: 2rem;
    display: flex;
    justify-content: center;
  }

  .ingredient__element{
    padding: 0 1rem;
    font-size: 1.2rem;
  }

  .step__element{
    padding: 0 1rem;
    font-size: 1.2rem;
    display: inline;
  }

  .categories__cloud{
    display: flex;
    flex-wrap: wrap;
  }

  .seasons__cloud{
    display: flex;
    flex-wrap: wrap;
  }
}
