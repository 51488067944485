@use 'src/styles/vars';

.inscription {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0rem;
  margin-bottom: 3rem;

  &__title {
    margin-bottom: 1.5rem;
  }

  &__content {
    min-width: 370px;
    display: flex;
    flex-direction: column;
    color: vars.$color-primary;
    background-color: vars.$color-secondary;
    border-radius: 1.5rem;
    padding: 1.5rem;
    margin: 0rem 5rem;

    &__text {
      margin-bottom: 1.5rem;
    }

    &__form {

    }

    &__inputs {
      margin-bottom: 1.5rem;
    }

    &__input {
      display: flex;
      flex-direction: column;
      margin: 0.6rem 0 0.3rem 0;
    }

    &__input:first-child {
      margin-top: 0rem;
    }

    &__label {
      font-size: 0.9rem;
      font-weight: 700;
      color: #000000;
      margin: 0 1.1rem 0.3rem 1.1rem;
    }

    &__field {
      border-radius: 1.3rem;
      border: 2px solid vars.$color-primary;
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
      background-color: vars.$color-sand;
    }

    &__field::placeholder {
      color: #00382a91;
    }

    &__footer {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__password {
        &__text {
          margin-bottom: 0.9rem;
        }

        &__instructions {
        list-style-type: initial;
        padding-left: 2.5rem;
        margin-bottom: 1.5rem;
        }
      }

      &__button {
        box-sizing: border-box;
        font-weight: 800;
        font-size: 0.85rem;
        text-transform: uppercase;
        padding: 0.75rem 1.25rem;
        margin: auto;
        color: vars.$color-secondary;
        border: 2px solid vars.$color-primary;
        background-color: vars.$color-primary;
        border-radius: 3rem;
        transition: 0.2s;
      }

      &__button:hover {
        border: 2px solid vars.$color-primary;
        background-color: vars.$color-sand;
        color: vars.$color-primary;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .inscription {
    &__content {
      max-width: 550px;
    }
  }
}
