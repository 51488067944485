@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;0,800;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Asap:ital,wght@0,400;0,700;1,400&display=swap');

$color-primary: #00382a;
$color-secondary: #bfe285;
$color-blue: #7becfe;
$color-sand: #fbf6ed;
$color-red: #c72538;
$font-text: 'Nunito', sans-serif;
//$font-text: 'Asap', sans-serif;
$font-title: 'Pacifico', cursive;
